/** Remains: Buildings **/

import React from "react";
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import Building1 from "../../components/remains/building1"
import Building2 from "../../components/remains/building2"
import Building3 from "../../components/remains/building3"
import Building4 from "../../components/remains/building4"
import Building5 from "../../components/remains/building5"
import Building6 from "../../components/remains/building6"
import Building7 from "../../components/remains/building7"
import SEO from "../../components/seo"
import gsap from "gsap";

let globalState, data, preloadData;

const buildingsWidth = 3457;
const buildingsHeight = 800;
const numBuildings = 7;
const defaultPositions = [35, 1061, 1319, 1588, 2506, 2771, 3211];
const LEFT = "left";
const RIGHT = "right";
const NONE = "none";
let buildings, b2, b1, bx = 0, buildingsWrapper, scrollFactor, scrollStrength = 0.1, currentScroll = 0, isScrolling = false;

let buildingsArr = [], startAnimation;


class RemainsPage2 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[2];
        preloadData = globalState.assets[3];

        //refs
        this.fullscreenBg = null;
    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        
        //show text
        gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });
        
        //reset buildings 
        bx = 0;
        currentScroll = 0;
        buildingsArr = [
            {x:0, width: 920, hasPlayed:false}, 
            {x:0, width: 145, hasPlayed:false}, 
            {x:0, width: 132, hasPlayed:false}, 
            {x:0, width: 851, hasPlayed:false}, 
            {x:0, width: 109, hasPlayed:false}, 
            {x:0, width: 405, hasPlayed:false},
            {x:0, width: 172, hasPlayed:false}
        ];

        setTimeout(() => {
            //add base track
            globalState.baseAudioRef.updateTrack(3, globalState.cdnUrl + data.soundfile1); //VO
            globalState.baseAudioRef.updateVolume(2, 60);
            globalState.baseAudioRef.updateTrack(1, false);
            isScrolling = true;
            this.reqAnimFrame = window.requestAnimationFrame(this.moveBuildings);
        }, 500);

        startAnimation = setTimeout(() => {
            buildingsArr[0].hasPlayed = true;
        this.refs.b1building1Ref.show();
        this.refs.b2building1Ref.show();
        }, 1500);
        

        //Update dimensions
        this.updateDimensions();

        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);

        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.text1, 
            globalState.cdnUrl + preloadData.bgVideoPoster,
        ], this.preloadCallback);

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);
        //gsap.killTweensOf(this.refs.text2Ref);

        clearTimeout(startAnimation);

        //stop the scroll
        this.stopBuildingsScroll();
        window.cancelAnimationFrame(this.reqAnimFrame);

        //remove page track
        globalState.baseAudioRef.updateTrack(3, false);

        //Remove resize listener
        window.removeEventListener("resize", this.updateDimensions);
    }


    
    isTransitioning = () => {
        
        //pause animations
    }

    updateDimensions = () => {
        let s = window.innerHeight / (buildingsHeight-150);
        let y = 0;
        if(s < 1){
            //s = 1;
            y = window.innerHeight - (s * buildingsHeight) + 1;
        }
        gsap.set(this.refs.buildingsWrapperRef, { scale: s});
        console.log(y);
        if(y < 0) {
            //y = 0;
            //gsap.set(this.refs.buildingsWrapperRef, { top: y});
        }
    }

    startBuildingsScroll = (e) => {
        
        let rect = e.target.getBoundingClientRect();
        let mx = e.clientX - rect.left;  //y position within the element.
        let sw = window.innerWidth;
        //let oneThird = sw / 3;
        let oneHalf = sw / 2;
        let curState;
        let strength = 0;
        if( mx < oneHalf ) {
            curState = LEFT;
        } else if( mx > oneHalf ){
            curState = RIGHT;
        } else {
            curState = NONE;
        }

        if( curState === LEFT ){
            strength = -(1 - (mx / oneHalf));
        } else if (curState === RIGHT){
            strength = (mx - oneHalf) / oneHalf;
        } 

        if(strength !== scrollStrength){
            scrollStrength = strength;
        }
        

        isScrolling = true;
        
      }
    
      stopBuildingsScroll = () => {
        isScrolling = false;
      }
    
      moveBuildings = () => {
        buildings = this.refs.buildingsRef;
        buildingsWrapper = this.refs.buildingsWrapperRef;
        b1 = this.refs.b1Ref;
        b2 = this.refs.b2Ref;
    
        if(scrollStrength > 0){
            buildingsWrapper.classList.add("right");
            buildingsWrapper.classList.remove("left");
        } else {
            buildingsWrapper.classList.remove("right");
            buildingsWrapper.classList.add("left");
        }
    
        //calculate where the two tiles should be positioned
        scrollFactor = -Math.ceil(currentScroll/(buildingsWidth));
        b1.style.left = ((scrollFactor) * buildingsWidth) + "px";
        b2.style.left = ((scrollFactor+1) * buildingsWidth) + "px";
        
        if (isScrolling){

          bx = currentScroll - (scrollStrength*15);
          buildings.style.left = bx + "px";
          currentScroll = bx;
        }
        
        //check if buildings are in view
        let sceneWidth = buildingsWidth;
        let pos = currentScroll % sceneWidth;
        
        //numBuildings
        for (var i = 0; i < numBuildings; i++) {
            let b = buildingsArr[i]
            //let b2 = this.refs["b2building"+(i+1)+"Ref"];
            let defaultPos = defaultPositions[i];
            
            b.x = defaultPos + pos;
            
            if (b.x + b.width < 0) {
                b.x += sceneWidth;
            } else if (b.x + b.width > sceneWidth) {
                b.x -= sceneWidth;
            }

            //console.log("building: " + (i+1) + ", hasPlayed: " + buildingsArr[i].hasPlayed + ", pos: " + pos + ", b.x: "+ b.x + ", b.width: " + b.width + ", sceneWidth: " + sceneWidth);
            
            if ( b.x > 500 && b.x < 600 && !buildingsArr[i].hasPlayed) {
                //playAll(b as MovieClip);
                console.log("SHOW building" + (i+1));
                buildingsArr[i].hasPlayed = true;
                this.refs["b1building"+(i+1)+"Ref"].show();
                this.refs["b2building"+(i+1)+"Ref"].show();
            }
        }
        
        
            //scroll buildings
            this.reqAnimFrame = window.requestAnimationFrame(this.moveBuildings);
        
      }


    render() {

        return (

            <>

                <link rel="prefetch" href="/remains/3" />
                {preloadData && (
                <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo} />
                )}

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/remains/3" prevLocation="/remains/1"
                    isTransitioning={this.isTransitioning} />
                {data && (
                <div className="fullpage-wrapper remains">
                    <SEO title="Pine Point - Remains" />

                    {/* Buildings */}
                    <div className="buildings-wrapper" ref="buildingsWrapperRef" role="button" tabIndex={0} onMouseMove={this.startBuildingsScroll} onMouseOut={this.stopBuildingsScroll} onBlur={this.stopBuildingsScroll}>
                    <div className="buildings" ref="buildingsRef">
                        <div className="b1" ref="b1Ref">
                            <img src={globalState.cdnUrl + data.background} alt="" />
                            <Building1 ref="b1building1Ref" file={globalState.cdnUrl + data.building1} fileColor={globalState.cdnUrl + data.building1Color} fileMask={globalState.cdnUrl + data.building1Mask}/>
                            <Building2 ref="b1building2Ref" file={globalState.cdnUrl + data.building2} fileColor={globalState.cdnUrl + data.building2Color} fileMask={globalState.cdnUrl + data.building2Mask}/>
                            <Building3 ref="b1building3Ref" file={globalState.cdnUrl + data.building3} fileColor={globalState.cdnUrl + data.building3Color} fileMask={globalState.cdnUrl + data.building3Mask}/>
                            <Building4 ref="b1building4Ref" file={globalState.cdnUrl + data.building4} file2={globalState.cdnUrl + data.building4base} fileColor={globalState.cdnUrl + data.building4Color} fileMask={globalState.cdnUrl + data.building4Mask}/>
                            <Building5 ref="b1building5Ref" file={globalState.cdnUrl + data.building5} fileColor={globalState.cdnUrl + data.building5Color} fileMask={globalState.cdnUrl + data.building5Mask} />
                            <Building6 ref="b1building6Ref" file={globalState.cdnUrl + data.building6} fileColor={globalState.cdnUrl + data.building6Color} fileMask={globalState.cdnUrl + data.building6Mask} />
                            <Building7 ref="b1building7Ref" file={globalState.cdnUrl + data.building7} fileColor={globalState.cdnUrl + data.building7Color} fileMask={globalState.cdnUrl + data.building7Mask} />
                        </div>
                        <div className="b2" ref="b2Ref">
                            <img src={globalState.cdnUrl + data.background} alt="" />
                            <Building1 ref="b2building1Ref" file={globalState.cdnUrl + data.building1} fileColor={globalState.cdnUrl + data.building1Color} fileMask={globalState.cdnUrl + data.building1Mask}/>
                            <Building2 ref="b2building2Ref" file={globalState.cdnUrl + data.building2} fileColor={globalState.cdnUrl + data.building2Color} fileMask={globalState.cdnUrl + data.building2Mask}/>
                            <Building3 ref="b2building3Ref" file={globalState.cdnUrl + data.building3} fileColor={globalState.cdnUrl + data.building3Color} fileMask={globalState.cdnUrl + data.building3Mask}/>
                            <Building4 ref="b2building4Ref" file={globalState.cdnUrl + data.building4} file2={globalState.cdnUrl + data.building4base} fileColor={globalState.cdnUrl + data.building4Color} fileMask={globalState.cdnUrl + data.building4Mask}/>
                            <Building5 ref="b2building5Ref" file={globalState.cdnUrl + data.building5} fileColor={globalState.cdnUrl + data.building5Color} fileMask={globalState.cdnUrl + data.building5Mask} />
                            <Building6 ref="b2building6Ref" file={globalState.cdnUrl + data.building6} fileColor={globalState.cdnUrl + data.building6Color} fileMask={globalState.cdnUrl + data.building6Mask} />
                            <Building7 ref="b2building7Ref" file={globalState.cdnUrl + data.building7} fileColor={globalState.cdnUrl + data.building7Color} fileMask={globalState.cdnUrl + data.building7Mask} />
                        </div>
                    </div>
                    </div>

                    <div className="text-wrapper">
                        <div className="text text--center-top" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "0", marginTop: "8vh", cursor: "default" }} alt="Absence preserves, and prevents what might have been. The corner store will never become an Arbys. Your family home won’t be painted, have an addition built, be razed to make way for a parking lot. Recollection will always be the most accurate version of that place and time." />
                        </div>
                        <img src={globalState.cdnUrl + data.caption} alt="DRAWINGS BY LYLE AND WAYNE" style={{ right: "40px", bottom: "40px", position: "absolute" }} />
                    </div>

                </div>
                )}
            </>
        );
    }


};

export default RemainsPage2
