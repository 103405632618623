import React from "react"
import { gsap, Quad } from "gsap"


class Building7 extends React.Component {

    componentDidMount = () => {
        
        gsap.set([this.refs.l1r1, this.refs.l1r3, this.refs.l1r7, this.refs.l1r9 ], {scaleX: 0});
        gsap.set([this.refs.colorMaskRef, this.refs.l1r2, this.refs.l1r4, this.refs.l1r5], {scaleY: 0});
        gsap.set([this.refs.l1r6, this.refs.l1r8], {scale: 0});
    }

    show = () => {
        gsap.to(this.refs.colorMaskRef, 1.5, {scaleY: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r1, 0.5, {scaleX: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r2, 0.5, {scaleY: 1, ease: Quad.easeOut, delay: 0.2});
        gsap.to(this.refs.l1r3, 0.4, {scaleX: 1, ease: Quad.easeOut, delay: 0.4});
        gsap.to(this.refs.l1r4, 0.5, {scaleY: 1, ease: Quad.easeOut, delay: 0.6});
        gsap.to(this.refs.l1r5, 0.3, {scaleY: 1, ease: Quad.easeOut, delay: 0.3});
        gsap.to(this.refs.l1r6, 0.4, {scale: 1, ease: Quad.easeOut, delay: 1});
        gsap.to(this.refs.l1r7, 0.5, {scaleX: 1, ease: Quad.easeOut, delay: 1.2});
        gsap.to(this.refs.l1r8, 0.5, {scale: 1, ease: Quad.easeOut, delay: 1.3});
        gsap.to(this.refs.l1r9, 0.3, {scaleX: 1, ease: Quad.easeOut, delay: 1.4});
    }
    
    render(){
        return (

            <svg className="building building7" width="172" height="237" viewBox="0 0 172 237" style={{left: "3211px", top: "302px"}}>
                <mask id="color-mask7">
                    <image ref="colorMaskRef" className="colorMask" width="172" height="237" xlinkHref={this.props.fileMask} alt="" />
                </mask>
                <mask id="line-mask7">
                    <rect ref="l1r1" className="" width="122" height="26" x="21" y="173" style={{fill:"white"}} />
                    <rect ref="l1r2" className="scaleUp" width="24" height="82" x="140" y="118" style={{fill:"white"}} />
                    <rect ref="l1r3" className="scaleRight" width="138" height="20" x="24" y="196" style={{fill:"white"}} />
                    <rect ref="l1r4" className="" width="30" height="100" x="0" y="116" style={{fill:"white"}} />
                    <rect ref="l1r5" className="" width="17" height="21" x="5" y="116" style={{fill:"white"}} />
                    <rect ref="l1r6" className="scaleUp" width="60" height="55" x="26" y="58" style={{fill:"white"}} />
                    <rect ref="l1r7" className="" width="150" height="10" x="22" y="111" style={{fill:"white"}} />
                    <rect ref="l1r8" className="scaleRight" width="92" height="95" x="80" y="28" style={{fill:"white"}} />
                    <rect ref="l1r9" className="" width="47" height="21" x="123" y="0" style={{fill:"white"}} />
                    
                    
                </mask>
                <image mask="url(#color-mask7)" width="154" height="157" x="13" y="55" xlinkHref={this.props.fileColor} alt="" />    
                <image mask="url(#line-mask7)" width="170" height="215" x="0" y="0" xlinkHref={this.props.file} alt="" />    
            </svg>
            
        );
    }
}

export default Building7
