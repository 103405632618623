import React from "react"
import { gsap, Quad } from "gsap"


class Building4 extends React.Component {


    componentDidMount = () => {
        gsap.set([this.refs.l1r3, this.refs.l1r4, this.refs.l1r7, this.refs.l1r9, this.refs.l1r10, this.refs.l1r11], {scaleX: 0});
        gsap.set([this.refs.colorMaskRef, this.refs.l1r1, this.refs.l1r2, this.refs.l1r5, this.refs.l1r6, this.refs.l1r8, this.refs.l1r12, this.refs.l1r13], {scaleY: 0});
    }

    show = () => {
        gsap.to(this.refs.colorMaskRef, 1.5, {scaleY: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r1, 0.7, {scaleY: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r2, 0.4, {scaleY: 1, ease: Quad.easeOut, delay: 0.5});
        gsap.to(this.refs.l1r3, 0.3, {scaleX: 1, ease: Quad.easeOut, delay: 0.9});

        gsap.to(this.refs.l1r4, 0.6, {scaleX: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r5, 0.6, {scaleY: 1, ease: Quad.easeOut, delay: 0.3});
        gsap.to(this.refs.l1r6, 0.6, {scaleY: 1, ease: Quad.easeOut, delay: 0.6});
        gsap.to(this.refs.l1r7, 0.6, {scaleX: 1, ease: Quad.easeOut, delay: 0.9});
        gsap.to(this.refs.l1r8, 0.6, {scaleY: 1, ease: Quad.easeOut, delay: 1.2});
        gsap.to(this.refs.l1r9, 0.6, {scaleX: 1, ease: Quad.easeOut, delay: 1.5});
        gsap.to(this.refs.l1r10, 0.6, {scaleX: 1, ease: Quad.easeOut, delay: 1.5});
        gsap.to(this.refs.l1r11, 0.6, {scaleX: 1, ease: Quad.easeOut, delay: 1.7});
        gsap.to(this.refs.l1r12, 0.6, {scaleY: 1, ease: Quad.easeOut, delay: 1.9});
        gsap.to(this.refs.l1r13, 0.6, {scaleY: 1, ease: Quad.easeOut, delay: 2.1});
    }
    
    render(){
        return (

            <svg className="building building4" width="851" height="313" viewBox="0 0 851 313" style={{left: "1588px", top: "276px"}}>
                <mask id="color-mask4">
                    <image ref="colorMaskRef" className="colorMask" width="851" height="313" xlinkHref={this.props.fileMask} alt="" />
                </mask>
                <mask id="line-mask4a">
                    <rect ref="l1r1" className="scaleRight" width="386" height="175" x="44" y="125" style={{fill:"white"}} />
                    <rect ref="l1r2" className="scaleUp" width="113" height="114" x="483" y="180" style={{fill:"white"}} />
                    <rect ref="l1r3" className="" width="217" height="110" x="586" y="143" style={{fill:"white"}} />
                </mask>
                <mask id="line-mask4b">
                    <rect ref="l1r4" className="" width="134" height="32" x="32" y="46" style={{fill:"white"}} />
                    <rect ref="l1r5" className="" width="16" height="105" x="165" y="49" style={{fill:"white"}} />
                    <rect ref="l1r6" className="" width="17" height="194" x="29" y="80" style={{fill:"white"}} />
                    <rect ref="l1r7" className="" width="749" height="26" x="47" y="139" style={{fill:"white"}} />
                    <rect ref="l1r8" className="" width="16" height="137" x="795" y="139" style={{fill:"white"}} />
                    <rect ref="l1r9" className="scaleRight" width="375" height="21" x="436" y="274" style={{fill:"white"}} />
                    <rect ref="l1r10" className="" width="127" height="14" x="37" y="274" style={{fill:"white"}} />
                    <rect ref="l1r11" className="scaleRight" width="291" height="22" x="158" y="161" style={{fill:"white"}} />
                    <rect ref="l1r12" className="scaleUp" width="285" height="119" x="149" y="181" style={{fill:"white"}} />
                    <rect ref="l1r13" className="scaleUp" width="117" height="58" x="47" y="79" style={{fill:"white"}} />
                    
                </mask>
                <image mask="url(#color-mask4)" width="773" height="249" x="35" y="47" xlinkHref={this.props.fileColor} alt="" />    
                <image mask="url(#line-mask4b)" width="780" height="245" x="35" y="47" xlinkHref={this.props.file} alt="" />    
                <image mask="url(#line-mask4a)" width="780" height="245" x="35" y="47" xlinkHref={this.props.file2} alt="" />    
            </svg>

        );
    }
}

export default Building4
