import React from "react"
import { gsap, Quad } from "gsap"


class Building3 extends React.Component {

    componentDidMount = () => {
        gsap.set([this.refs.colorMaskRef, this.refs.l1r6], {scaleY: 0});
        gsap.set([this.refs.l1r1, this.refs.l1r2, this.refs.l1r3, this.refs.l1r4, this.refs.l1r5], {scaleX: 0});
    }

    show = () => {
        gsap.to(this.refs.colorMaskRef, 1.5, {scaleY: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r1, 0.6, {scaleX: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r2, 0.6, {scaleX: 1, ease: Quad.easeOut, delay: 0.3});
        gsap.to(this.refs.l1r3, 0.6, {scaleX: 1, ease: Quad.easeOut, delay: 0.5});
        gsap.to(this.refs.l1r4, 0.6, {scaleX: 1, ease: Quad.easeOut, delay: 0.7});
        gsap.to(this.refs.l1r5, 0.6, {scaleX: 1, ease: Quad.easeOut, delay: 0.9});
        gsap.to(this.refs.l1r6, 0.6, {scaleY: 1, ease: Quad.easeOut, delay: 1.1});
    }
    
    render(){
        return (
            <svg className="building building3" width="132" height="101" viewBox="0 0 135 117" style={{left: "1319px", top: "425px"}}>
                <mask id="color-mask3">
                    <image ref="colorMaskRef" className="colorMask" width="132" height="101" xlinkHref={this.props.fileMask} alt="" />
                </mask>
                <mask id="line-mask3">
                    <rect ref="l1r1" className="scaleRight" width="90" height="15" x="35" y="15" style={{fill:"white"}} />
                    <rect ref="l1r2" className="scaleRight" width="90" height="15" x="32" y="49" style={{fill:"white"}} />
                    <rect ref="l1r3" className="scaleRight" width="97" height="15" x="30" y="87" style={{fill:"white"}} />
                    <rect ref="l1r4" className="scaleRight" width="103" height="30" x="40" y="25" style={{fill:"white"}} />
                    <rect ref="l1r5" className="scaleRight" width="105" height="28" x="38" y="59" style={{fill:"white"}} />
                    <rect ref="l1r6" className="scaleUp" width="34" height="104" x="3" y="17" style={{fill:"white"}} />
                </mask>
                <image mask="url(#color-mask3)" width="106" height="85" x="10" y="14" xlinkHref={this.props.fileColor} alt="" />    
                <image mask="url(#line-mask3)" width="102" height="78" x="8" y="18" xlinkHref={this.props.file} alt="" />    
            </svg>
        );
    }
}

export default Building3
