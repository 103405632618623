import React from "react"
import { gsap, Quad } from "gsap"


class Building2 extends React.Component {

    componentDidMount = () => {
        gsap.set([this.refs.colorMaskRef, this.refs.l1r2, this.refs.l1r3], {scaleY: 0});
        gsap.set([this.refs.l1r1, this.refs.l1r4], {scaleX: 0});
        gsap.set([this.refs.l1r5, this.refs.l1r6, this.refs.l1r7], {scale: 0});
    }

    show = () => {
        gsap.to(this.refs.colorMaskRef, 1.5, {scaleY: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r1, 0.4, {scaleX: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r2, 0.4, {scaleY: 1, ease: Quad.easeOut, delay:0.2});
        gsap.to(this.refs.l1r3, 0.4, {scaleY: 1, ease: Quad.easeOut, delay:0.4});
        gsap.to(this.refs.l1r4, 0.4, {scaleX: 1, ease: Quad.easeOut, delay:0.6});
        gsap.to(this.refs.l1r5, 0.1, {scale: 1, ease: Quad.easeOut, delay:1});
        gsap.to(this.refs.l1r6, 0.4, {scale: 1, ease: Quad.easeOut, delay:1.2});
        gsap.to(this.refs.l1r7, 0.4, {scale: 1, ease: Quad.easeOut, delay:1.4});
    }
    
    render(){
        return (
            <svg className="building building2" width="145" height="176" viewBox="0 0 145 176" style={{left: "1061px", top: "393px"}}>
                <mask id="color-mask2">
                    <image ref="colorMaskRef" className="colorMask" width="145" height="176" xlinkHref={this.props.fileMask} alt="" />
                </mask>
                <mask id="line-mask2">
                    <rect ref="l1r1" width="119" height="15" x="14" y="148" style={{fill:"white"}} />
                    <rect ref="l1r2" className="scaleUp" width="9" height="60" x="4" y="101" style={{fill:"white"}} />
                    <rect ref="l1r3" className="scaleUp" width="12" height="63" x="129" y="95" style={{fill:"white"}} />
                    <rect ref="l1r4" className="scaleRight" width="121" height="18" x="20" y="91" style={{fill:"white"}} />
                    <rect ref="l1r5" className="" width="22" height="15" x="13" y="99" style={{fill:"white"}} />
                    <rect ref="l1r6" className="scaleRight" width="64" height="59" x="76" y="38" style={{fill:"white"}} />
                    <rect ref="l1r7" className="" width="79" height="65" x="0" y="45" style={{fill:"white"}} />
                </mask>
                <image mask="url(#color-mask2)" width="137" height="122" x="0" y="45" xlinkHref={this.props.fileColor} alt="" />    
                <image mask="url(#line-mask2)" width="135" height="117" x="0" y="45" xlinkHref={this.props.file} alt="" />    
            </svg>
        );
    }
}

export default Building2
