import React from "react"
import { gsap, Quad } from "gsap"


class Building1 extends React.Component {

    componentDidMount = () => {
        gsap.set(this.refs.colorMaskRef, {scaleY: 0});
        gsap.set([this.refs.l1r1, this.refs.l1r10, this.refs.l1r11], {scaleX: 0});
        gsap.set([this.refs.l1r2, this.refs.l1r3, this.refs.l1r4, this.refs.l1r5, this.refs.l1r6, this.refs.l1r7, this.refs.l1r8, this.refs.l1r9], {scaleY: 0});
    }

    show = () => {
        gsap.to(this.refs.colorMaskRef, 1.5, {scaleY: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r11, 1.2, {scaleX: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r10, 1, {scaleX: 1, ease: Quad.easeOut, delay:0.2});
        gsap.to(this.refs.l1r9, 0.7, {scaleY: 1, ease: Quad.easeOut, delay:0.3});
        
        gsap.to(this.refs.l1r1, 1.2, {scaleX: 1, ease: Quad.easeOut, delay: 1.2});
        gsap.to(this.refs.l1r2, 0.5, {scaleY: 1, ease: Quad.easeOut, delay: 1.1});
        gsap.to(this.refs.l1r3, 0.5, {scaleY: 1, ease: Quad.easeOut, delay: 0.9});
        gsap.to(this.refs.l1r4, 0.7, {scaleY: 1, ease: Quad.easeOut, delay: 1.2});
        gsap.to(this.refs.l1r5, 0.6, {scaleY: 1, ease: Quad.easeOut, delay: 1.1});
        gsap.to(this.refs.l1r6, 0.6, {scaleY: 1, ease: Quad.easeOut, delay: 0.9});
        gsap.to(this.refs.l1r7, 0.6, {scaleY: 1, ease: Quad.easeOut, delay: 0.8});
        gsap.to(this.refs.l1r8, 0.6, {scaleY: 1, ease: Quad.easeOut, delay: 0.7});
    }
    
    render(){
        return (
            
            <svg className="building building1" width="920" height="240" viewBox="0 0 920 240" style={{left: "18px", top: "300px"}}>
                <mask id="color-mask" width="920" height="240">
                    <image ref="colorMaskRef" className="colorMask" width="920" height="240" xlinkHref={this.props.fileMask} alt="" />
                </mask>
                <mask id="line-mask">
                    <rect ref="l1r1" width="871" height="175" x="7" y="61" style={{fill:"white"}} />
                    <rect ref="l1r2" width="46" height="98" x="0" y="106" style={{fill:"white"}} />
                    <rect ref="l1r3" width="68" height="61" x="91" y="140" style={{fill:"white"}} />
                    <rect ref="l1r4" width="68" height="72" x="726" y="146" style={{fill:"white"}} />
                    <rect ref="l1r5" width="81" height="62" x="544" y="157" style={{fill:"white"}} />
                    <rect ref="l1r6" width="51" height="80" x="394" y="132" style={{fill:"white"}} />
                    <rect ref="l1r7" width="53" height="56" x="488" y="114" style={{fill:"white"}} />
                    <rect ref="l1r8" width="53" height="54" x="629" y="129" style={{fill:"white"}} />
                    <rect ref="l1r9" width="21" height="206" x="856" y="30" style={{fill:"white"}} />
                    <rect ref="l1r10" width="439" height="15" x="418" y="96" style={{fill:"white"}} />
                    <rect ref="l1r11" width="849" height="20" x="28" y="48" style={{fill:"white"}} />
                </mask>
                <image mask="url(#color-mask)" width="857" height="186" x="17" y="50" xlinkHref={this.props.fileColor} alt="" />    
                <image mask="url(#line-mask)" width="854" height="187" x="17" y="50" xlinkHref={this.props.file} alt="" />    
            </svg>
                
        );
    }
}

export default Building1
