import React from "react"
import { gsap, Quad } from "gsap"


class Building6 extends React.Component {

    componentDidMount = () => {
        
        gsap.set([this.refs.l1r2, this.refs.l1r6, this.refs.l1r9, this.refs.l1r10, this.refs.l1r11 ], {scaleX: 0});
        gsap.set([this.refs.colorMaskRef, this.refs.l1r1, this.refs.l1r3, this.refs.l1r4, this.refs.l1r5, this.refs.l1r7, this.refs.l1r8, this.refs.l1r12], {scaleY: 0});
    }

    show = () => {
        gsap.to(this.refs.colorMaskRef, 1.5, {scaleY: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r1, 0.5, {scaleY: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r2, 0.5, {scaleX: 1, ease: Quad.easeOut, delay: 0.2});
        gsap.to(this.refs.l1r3, 0.4, {scaleY: 1, ease: Quad.easeOut, delay: 0.4});
        gsap.to(this.refs.l1r4, 0.5, {scaleY: 1, ease: Quad.easeOut, delay: 0.5});
        gsap.to(this.refs.l1r5, 0.5, {scaleY: 1, ease: Quad.easeOut, delay: 0.7});
        gsap.to(this.refs.l1r6, 0.5, {scaleX: 1, ease: Quad.easeOut, delay: 1});
        gsap.to(this.refs.l1r7, 0.7, {scaleY: 1, ease: Quad.easeOut, delay: 1.2});
        gsap.to(this.refs.l1r8, 0.6, {scaleY: 1, ease: Quad.easeOut, delay: 1.3});
        gsap.to(this.refs.l1r9, 0.5, {scaleX: 1, ease: Quad.easeOut, delay: 0.9});
        gsap.to(this.refs.l1r10, 0.5, {scaleX: 1, ease: Quad.easeOut, delay: 1.5});
        gsap.to(this.refs.l1r11, 0.5, {scaleX: 1, ease: Quad.easeOut, delay: 1.7});
        gsap.to(this.refs.l1r11, 0.3, {scaleY: 1, ease: Quad.easeOut, delay: 1.8});
    }
    
    render(){
        return (
            <svg className="building building6" width="405" height="155" viewBox="0 0 405 155" style={{left: "2771px", top: "350px"}}>
                <mask id="color-mask6">
                    <image ref="colorMaskRef" className="colorMask" width="405" height="155" xlinkHref={this.props.fileMask} alt="" />
                </mask>
                <mask id="line-mask6">
                    <rect ref="l1r1" className="scaleUp" width="10" height="90" x="3" y="26" style={{fill:"white"}} />
                    <rect ref="l1r2" className="" width="139" height="19" x="6" y="28" style={{fill:"white"}} />
                    <rect ref="l1r3" className="scaleUp" width="53" height="36" x="88" y="70" style={{fill:"white"}} />
                    <rect ref="l1r4" className="" width="218" height="14" x="151" y="26" style={{fill:"white"}} />
                    <rect ref="l1r5" className="" width="13" height="120" x="369" y="22" style={{fill:"white"}} />
                    <rect ref="l1r6" className="scaleRight" width="228" height="18" x="155" y="128" style={{fill:"white"}} />
                    <rect ref="l1r7" className="scaleUp" width="40" height="70" x="195" y="72" style={{fill:"white"}} />
                    <rect ref="l1r8" className="" width="124" height="53" x="239" y="63" style={{fill:"white"}} />
                    <rect ref="l1r9" className="" width="142" height="21" x="184" y="41" style={{fill:"white"}} />
                    <rect ref="l1r10" className="" width="75" height="25" x="31" y="50" style={{fill:"white"}} />
                    <rect ref="l1r11" className="scaleRight" width="142" height="15" x="6" y="121" style={{fill:"white"}} />
                    <rect ref="l1r12" className="" width="54" height="50" x="31" y="76" style={{fill:"white"}} />
                    
                    
                </mask>
                <image mask="url(#color-mask6)" width="373" height="117" x="6" y="24" xlinkHref={this.props.fileColor} alt="" />    
                <image mask="url(#line-mask6)" width="375" height="120" x="6" y="24" xlinkHref={this.props.file} alt="" />    
            </svg>
            
        );
    }
}

export default Building6
