import React from "react"
import { gsap, Quad } from "gsap"


class Building5 extends React.Component {

    componentDidMount = () => {
        gsap.set([this.refs.l1r4, this.refs.l1r6], {scaleX: 0});
        gsap.set([this.refs.colorMaskRef, this.refs.l1r1, this.refs.l1r2, this.refs.l1r5, this.refs.l1r7, this.refs.l1r8], {scaleY: 0});
        gsap.set([this.refs.l1r3, this.refs.l1r9 ], {scale: 0});
    }

    show = () => {
        gsap.to(this.refs.colorMaskRef, 1.5, {scaleY: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r1, 0.5, {scaleY: 1, ease: Quad.easeOut});
        gsap.to(this.refs.l1r2, 0.2, {scaleY: 1, ease: Quad.easeOut, delay: 0.5});
        gsap.to(this.refs.l1r3, 0.4, {scale: 1, ease: Quad.easeOut, delay: 0.6});
        gsap.to(this.refs.l1r4, 0.5, {scaleX: 1, ease: Quad.easeOut, delay: 0.9});
        gsap.to(this.refs.l1r5, 0.5, {scaleY: 1, ease: Quad.easeOut, delay: 1});
        gsap.to(this.refs.l1r6, 0.5, {scaleX: 1, ease: Quad.easeOut, delay: 1.2});
        gsap.to(this.refs.l1r7, 0.5, {scaleY: 1, ease: Quad.easeOut, delay: 1.4});
        gsap.to(this.refs.l1r8, 0.3, {scaleY: 1, ease: Quad.easeOut, delay: 1.5});
        gsap.to(this.refs.l1r9, 0.3, {scale: 1, ease: Quad.easeOut, delay: 1.6});
    }
    
    render(){
        return (

            <svg className="building building5" width="109" height="152" viewBox="0 0 109 152" style={{left: "2506px", top: "346px"}}>
                <mask id="color-mask5">
                    <image ref="colorMaskRef" className="colorMask" width="109" height="152" xlinkHref={this.props.fileMask} alt="" />
                </mask>
                <mask id="line-mask5">
                    <rect ref="l1r1" className="scaleUp" width="17" height="50" x="87" y="106" style={{fill:"white"}} />
                    <rect ref="l1r2" className="scaleUp" width="6" height="7" x="92" y="100" style={{fill:"white"}} />
                    <rect ref="l1r3" className="scaleRight" width="48" height="51" x="57" y="49" style={{fill:"white"}} />
                    <rect ref="l1r4" className="scaleRight" width="78" height="8" x="25" y="99" style={{fill:"white"}} />
                    <rect ref="l1r5" className="" width="35" height="27" x="61" y="113" style={{fill:"white"}} />
                    <rect ref="l1r6" className="scaleRight" width="84" height="16" x="24" y="136" style={{fill:"white"}} />
                    <rect ref="l1r7" className="scaleUp" width="58" height="75" x="10" y="76" style={{fill:"white"}} />
                    <rect ref="l1r8" className="scaleUp" width="33" height="29" x="31" y="55" style={{fill:"white"}} />
                    <rect ref="l1r9" className="scaleUp" width="105" height="82" x="2" y="1" style={{fill:"white"}} />
                    
                </mask>
                <image mask="url(#color-mask5)" width="92" height="101" x="11" y="47" xlinkHref={this.props.fileColor} alt="" />    
                <image mask="url(#line-mask5)" width="109" height="152" x="0" y="0" xlinkHref={this.props.file} alt="" />    
            </svg>

        );
    }
}

export default Building5
